import React, { useState } from 'react';
import styled from "styled-components";
// Assets
import ContactImg1 from "../../assets/img/markus-spiske-2KxcdGle_FA-unsplash.jpg";
import ContactImg2 from "../../assets/img/charlesdeluvio-1zIP0skBAfE-unsplash.jpg";
import ContactImg3 from "../../assets/img/headway-5QgIuuBxKwM-unsplash.jpg";

export default function Contact() {
  const [loading, setLoading] = useState(false); // State to track loading status
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    message: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

 
const handleSubmit = async (e) => {
  e.preventDefault();
  setLoading(true);

  // Create a new object only with non-null values
  const filteredData = Object.fromEntries(
    Object.entries(formData).filter(([key, value]) => value !== null && value !== '')
  );

  try {
    const response = await fetch('https://bk.2asols.com/api/web_apis/contact/form/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(filteredData), // Send only the filtered data
    });

    // Parse the JSON response
    const result = await response.json();

    if (response.ok && result.success) {
      alert(result.message); // Display success message from backend
      setFormData({
        name: '',
        email: '',
        company: '',
        message: '',
      });
    } else {
      // Handle error case and display the error message from backend
      alert(result.message || 'Failed to send message. Please try again.');
    }
  } catch (error) {
    console.error('Error:', error);
    alert('An unexpected error occurred. Please try again.');
  }
   finally {
  setLoading(false); // Reset loading to false after response
}
};


  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's get in touch</h1>
            <p className="font13">
            Have a question or a project in mind? Don’t hesitate to reach out—our team is here to help you every step of the way!
              {/* <br />
              labore et dolore magna aliquyam erat, sed diam voluptua. */}
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <Form id="contactForm" onSubmit={handleSubmit}>
                <label className="font13">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="font20 extraBold"
                />
                <label className="font13">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="font20 extraBold"
                />
                <label className="font13">Company:</label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  value={formData.company}
                  onChange={handleInputChange}
                  className="font20 extraBold"
                />
                <textarea
                  rows="4"
                  cols="50"
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  className="font20 extraBold"
                />
              </Form>
               {/* Conditionally render the submit button based on loading state */}
               {!loading && (
              <SumbitWrapper className="flex">
                <ButtonInput type="submit" value="Send Message" 
                className="pointer animate radius8" style={{ maxWidth: "220px" }} 
                onClick={() => {
                  handleSubmit(new Event('submit', { cancelable: true })); // Call handleSubmit
                }}/>
              </SumbitWrapper>
              )}
              {loading && <p className="font13">Sending...</p>} {/* Display loading message */}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
                <ContactImgBox>
                  <img src={ContactImg1} alt="office" style={{ width: "180px", height: "204px" }}  className="radius6" />
                </ContactImgBox>
                <ContactImgBox>
                  <img src={ContactImg2} alt="office" style={{ width: "180px", height: "295px" }} className="radius6" />
                </ContactImgBox>
              </div>
              <div style={{ width: "50%" }}>
                <div style={{ marginTop: "100px" }}>
                  <img src={ContactImg3} alt="office" style={{ width: "278px", height: "330px" }} className="radius6" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









