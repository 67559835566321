import React from "react";
import styled from "styled-components";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";
import backend from "../../assets/img/backend.png";
import dashboard from "../../assets/img/dashboard.png";
import datascience from "../../assets/img/data-science.png";
import machinelearning from "../../assets/img/machine-learning.png";



export default function ServiceBox({icon, title, subtitle}) {
  let getIcon;

  switch (icon) {
    case "roller":
      getIcon = <img src={backend} />;
      break;
    case "monitor":
      getIcon = <img src={machinelearning} />;
      break;
    case "browser":
      getIcon = <img src={datascience} />;
      break;
    case "printer":
      getIcon = <img src={dashboard} />;
      break;
    default:
      getIcon = <RollerIcon />;
      break;
  }


  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
// const IconStyle = styled.div`
//   @media (max-width: 860px) {
//     margin: 0 auto;
//   }
// `;
const IconStyle = styled.div`
  img {
    width: 50px; /* Set the width you need */
    height: 50px; /* Set the height you need */
  }
  
  @media (max-width: 860px) {
    margin: 0 auto;
    img {
      width: 40px; /* Adjust for smaller screens */
      height: 40px;
    }
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;